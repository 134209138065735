import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Capricorn.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
              Capricorn Traits
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/capricorn"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Capricorn </h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Capricorn Traits</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Capricorn Traits</h1>
                <h2 className="text-sm md:text-base ml-4">Dec 22 - Jan 19</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-love");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-nature");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-man");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-traits");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-facts");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Capricorn Traits</p><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Positive Traits:</p>Loyal, Hardworking, Team player, Ambitious and Classy
<br></br>
<p className="font-bold text-orange-500 inline-block mr-2">Negative Traits:</p> Overly Serious, Critical, Melancholy, Unforgiving, and Suspicious
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Capricorn Positive Traits</p><br/>
 <p className="playfair text-black text-xl md:text-3xl">Loyal</p> <br/>
All that being said, Capricorns are one of the most loyal and pure-hearted zodiac signs around. They're not going to give you any trouble, and they're always going to be there for you when you need them. They're very loyal to the people they care about, and they're not afraid to show their love. They're also faithful and dedicated to their goals and dreams.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Hardworking</p><br/>
Capricorn people are very hard workers; they are loyal to their families and work hard to achieve their goals. They are generally honest and reliable, and they can be very stubborn.
They are very loyal and devoted to their family, friends, and community. Although they are very organized and diligent, they tend to be perfectionists. They are very responsible for the welfare of their loved ones.
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Team player</p><br/>
Capricorns are excellent at blending into a group and will often look like one of the team's original members. They are a great team player, as they will do anything to assist their colleagues. They are a hard worker, and they do not like to let anyone down. They will do whatever it takes to ensure the job gets done, and they are always the first to finish on time.<br/><br/>

 <p className="playfair text-black text-xl md:text-3xl">Ambitious</p><br/>
Capricorns are driven to succeed to achieve their goals and are fiercely ambitious. They are profoundly determined and work towards a better and more prosperous future.<br/><br/>
Capricorns are practical, hardworking, and sometimes overbearing people. Their high energy levels, ambition, and determination make them excellent leaders, and they are often highly successful.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Classy</p><br/>
Capricorns are always Elegant, Charming, and Compassionate. Capricorns are known for their grace and charm. They do not have a mean temper but do not allow it to get the best of them. Capricorns are peaceful being who is always concerned with the welfare of others. They are usually found in offices, warehouses, and factories.<br/><br/>

Some Capricorns are excellent teachers. Their words and actions always show they want to make students feel good and care about them. They seem to have an innate ability to work with others and be kind. That is why they are a great teacher.
They don't follow crazy trends unless they feel they can make it classy.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Capricorn Negative Traits</p><br/>
 <br></br>
 <p className="playfair text-black text-xl md:text-3xl">Overly Serious</p><br/>
Capricorns are serious and responsible individuals, and they tend to be the ones to take on the most responsibility. They are determined to protect and provide for others, even working long hours or putting in long days. They have a strong sense of duty, and once they make a decision, they are committed to seeing it through. Others need to recognize this side of them, as it provides an essential contrast to their more playful side.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Critical</p><br/>
Capricorns are often overly critical of others. They expect others to live by their rules, which might pressure their near ones. The Capricorn can be a real taskmaster, especially if they feel their position is being threatened.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Melancholy</p><br/>
If the Capricorns feel that they are in such a bad status, they have to make themselves happy as soon as possible. There could be times when the Capricorns might seem melancholy and also depressed. They tend to become melancholy, due to which they want someone who can make them happy as well. They are not that worried about the future and are always satisfied with their present status.<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Unforgiving</p>
One of the unknown traits of Capricorns is their unforgiving nature. Capricorns are known to be very ambitious, hardworking, and determined personalities. Capricorns are usually very loyal to those they see as worthy of their time with many talents and skills. <br/><br/>
The personality traits of a Capricorn are, by their very nature, unemotional, disciplined, detail-oriented, and most important unforgiving.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Suspicious</p><br/>
Capricorns are naturally suspicious people and will trust you until you break their trust, and they hate when things are going too well and think everyone is out to get them.
They are naturally suspicious people and will trust you until you give them a reason not to.
 <br/><br/>
Understanding the Capricon in love will help you build better relationships.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Capricorn Love And Relationships</p><br/>
Capricorn is the most loyal of all the zodiac. They tend to love the same person for a long time, and they will sacrifice everything for those they love. Capricorn is the most loyal of all the zodiac. They are devoted to the person they love for a long time, and they will sacrifice everything for those they love.<br/><br/>
Capricorns are also highly protective of their own families and close friends. They are loyal, dedicated, and devoted as they desire to build a genuinely long-standing future for both themselves and their loved ones.
 <br/><br/>
The Capricorn is very loyal and devoted to their family and friends. They are usually very loyal to their partner and their family. They will do almost anything for their family and put them before themselves, even neglecting their well-being.
 <br/><br/>
They are devoted to their relationships and long-term stability. In their relationships, they do not just want to be with their partner or spouse for life; they want to build a long-term future together. They want to see their relationship through to the end.
Capricorns in a relationship are incredibly loyal and devoted as they desire to build a genuinely long-standing future for themselves and their loved ones. They are not easily swayed from their path but tend to be more vulnerable to peer pressure.
 <br/><br/>
They are like an iceberg that will surprise you with their depth and rich experience in life once they are in a deep relationship. They are very loyal and devoted to their partner and family. Known for their reliability, Capricorn is dedicated to a fault. They will always be there for you, trying their best to ensure you are happy and cared for. They will do anything and everything to make you happy, which can be a bit overwhelming at times. But once they like you, you will find that they will take care of your basic needs.
 <br/><br/>
 
 
 <p className="playfair text-black text-xl md:text-3xl">Capricorn Family and Friends</p><br/>
 
In a way, the most crucial part of Capricorn's life is the home. They need to take care of themselves and their family, which is why they are so severe and will work extra hard to improve the quality of their home. This is the part where they come to the attention of the astrological authorities and will work harder to improve the quality of their home. In other words, they are very focused on the house, and it shows.
 <br/><br/>
 
Capricorns are true to their word; once they commit to their friends and loved ones, they keep going until they see it through. This makes them dependable friends; they are always there for you when you need them. However, this also causes them to be very protective of their loved ones; they will do their best not to let them down. Soon, once they like you, they will also take care of your basic needs.
 <br/><br/>
Capricorns are not the most affectionate of signs, but they like to take care of their loved ones. They are dependable, and they don't want to let people down. Once they like you, Capricorn will take care of your needs too. They are known for being very strong-willed and determined.
Capricorn is a hard person to like at first glance. They are serious, responsible, and not at all funny. But once they get to know you, they are incredibly loyal. They take care of their friends and family more than anything in their life.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Capricorn Career and Money</p>
 <br></br>
 <p className="playfair text-black text-xl md:text-3xl">Capricorn and Career</p><br/>
A career in the area of life where ambitious, creative, and often driven by the need to be the best they can be. The Capricorn is apt to shine the brightest. Born leaders, Capricorns are at their best in the workplace. Capricorn's career Mantra is "I Have Got This."
 <br/><br/>
Determination is the key to success in the lives of many people. However, we all are aware that determination alone does not guarantee success. One must have the knowledge and skill to achieve success. But even with a strong determination, one may not be able to achieve success when all the odds are against him.
 <br/><br/>
In the context of business, Capricorn is a sign that has a lot of success in the long run. Capricorn is the sign of the zodiac that is ruled by the planet Saturn. Capricorn is the sign of the earth element and the element of willpower and determination.
The Capricorn will have a clear goal in mind and will achieve it. They can be very hardworking and dedicated. They enjoy leadership and are very efficient. They are ambitious, and they work well with others.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Capricorn and Money</p><br/>
Capricorn is excellent at saving and making money. They often have a keen mind for investments. They are known to be very intelligent and clever. They often make money, and they have good sense and judgment.
 <br/><br/>
 They value putting their money aside for rainy days and have a keen mentality for investments. They do not like to spend money on unnecessary things. 
 <br/><br/>
A Capricorn sees the investment as their hobby and thus, is good at investments. They never put their money at risk and are very cautious when making money decisions.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">How To Attract Capricorn</p><br/>
You can't easily win Capricorn's hearts. It's more like a challenge! Please read below to find out how to attract Capricorn and what ticks them off, sets their tempers, and wins their hearts.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Show Up Your Intelligence:</p>Capricorn-like intellectual individuals who know what they want from themselves. So to attract them, showcase your intelligence as they always look for someone they can rely upon. But most importantly, don't try to change them.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Be Ready For Adventure:</p>Capricorns are very adventurous. They're the type of people who will go to new places and try new things, even if uncomfortable. It makes them great travelers, as they can go to places they might not otherwise have been able to go to.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Support Their Goals:</p>People who follow the Capricorn path want to be the best version of themselves. They want to make a difference and use their gifts in important ways. They also want to be at the forefront of change and innovation. They encourage other people as well to do great things in life. <br/>
<br></br>
<p className="font-bold text-orange-500 inline-block mr-2">Dress Well:</p>
<br></br>

According to the occasion, when you are with Capricorn, make sure you dress well.<br/><br/>
People recognize you and find you attractive once you start to wear your confidence.<br/><br/>
Always try to impress other people with your presence.
 <br/><br/>
The Capricorn personality traits are often the result of an individual's core personality, social influences, and life experiences. It is a combination of what you bring to the table and what you do not. You value loyalty, hard work, service, and honesty as a Capricorn. You are a conscious and responsible person.
 <br/><br/>
Compatible Signs Capricorn Should Consider
 The most compatible zodiac signs with Capricorn are typically Taurus, Pisces, and Virgo.
 <br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
